.formWrapper {
	width: 100%;
	container-type: inline-size;
	position: relative;
}

.submissionStatus {
	position: absolute;
	top: 0;
	left: 0;
}

.form {
	/* 
    The following styles allow us to place the form button alongside the email field in the first
    row. 
  */

	display: grid;
	width: 100%;
	row-gap: var(--wpl-spacing-02);

	& [data-field-id='Email'],
	& [data-field-id='Consent_Privacy_Policy__c'] {
		margin: 0;
	}

	& [data-field-id='Email'] > div {
		margin-bottom: 0;
	}

	& [data-field-id='Email'] label {
		display: none;
	}

	& button {
		justify-self: stretch;
		align-self: start;
	}

	@container (min-width: 1000px) {
		grid-template-columns: repeat(12, 1fr);
		column-gap: var(--wpl-spacing-03);
		transition: opacity 0.35s;

		& [data-field-id='privacy-policy-text'] {
			grid-row: 1 / 3;
			grid-column: 1 / 6;

			& span {
				margin-top: 0;
				margin-bottom: 0;
			}
		}

		& [data-field-id='Email'] {
			grid-column: 7 / 12;
			word-break: break-all;
		}

		& button {
			margin-top: 8px;
			align-self: start;
			grid-row: 1 / 2;
			grid-column: 12 / 13;
		}

		& [data-field-id='Consent_Privacy_Policy__c'] {
			grid-row: 2 / 3;
			grid-column: 7 / 13;
		}
	}
}
